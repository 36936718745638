@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');
@import 'node_modules/bootstrap/scss/bootstrap';

$color-blue: rgb(0, 110, 255);
$color-gray: #4C4C4C;
$color-gray-dark: #4C5E7F;
$color-light-gray: #8B8B8B;
$color-light-gray-2: #F3F3F3;
$color-light-gray-3: #9BA4B5;

.c-gray { color: $color-gray; }
.c-gray-dark { color: $color-gray-dark; }
.c-light-gray { color: $color-light-gray; }
.c-light-gray-3 { color: $color-light-gray-3; }
.c-blue { color: $color-blue; }

.b-blue { background-color: $color-blue; }
.b-gray-2 { background-color: $color-light-gray-2; }
.b-white { background-color: #FFFFFF; }

.btn-gray-2 {
  background-color: $color-light-gray-2;
  border-color: $color-light-gray-2;
  color: $color-gray;
  box-shadow: none;

  &:not(:disabled) {
    &:hover,
    &:focus,
    &:active {
      background-color: darken($color-light-gray-2, 10%) !important;
      border-color: darken($color-light-gray-2, 10%) !important;
      color: lighten($color-gray, 10%) !important;
      box-shadow: none !important;
    }
  }
}

.fs-32 { font-size: 32px; }
.fs-20 { font-size: 20px; }
.fs-16 { font-size: 16px; }
.fs-14 { font-size: 14px; }
.fs-12 { font-size: 12px; }

.l-space-2 { letter-spacing: 2.06px; }

.m-t-20 { margin-top: 20px; }
.m-r-20 { margin-right: 20px; }
.m-r-5 { margin-right: 5px; }
.m-b-0 { margin-bottom: 0; }

.l-h-20 { line-height: 20px; }
.l-h-1 {line-height: 1; }

.align-vm { vertical-align: middle; }

h1 {
  margin-bottom: 20px;
}

.no-focus-shadow {
  &:focus {
    box-shadow: none !important;
    border-color: $color-blue;
  }
}

.form-control[readonly] {
  background-color: #FFFFFF;
}

.no-border-r { border-radius: 0; }

body {
  font-family: 'Poppins', sans-serif;
}

.margin {
  margin-top: 40px;
}

.features {
  margin-top: 40px;
}

pre {
  background-color: #ecf0f1;
  padding: 15px;
  border: 1px solid #cccccc;
  display: block;
  font-size: 14px;
  border-radius: 4px;
  word-wrap: break-word;
  word-break: break-all;
}

.feature-box .feature-img-icon {
  text-align: center;
  background: #fff;
  padding-top: 20px;
  border-radius: 100%;
  margin-right: 16px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #fff;
}
.feature-box {
  display: flex;
  margin-bottom: 90px;
  background: #fff;
  padding: 12px 32px;
  border: 1px solid $color-blue;
  border-radius: 3px;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.section-title {
  text-align: center;
}

.footer {
  margin-bottom: 25px;
}

li {
  list-style: none;
}

.file-upload {
  margin-bottom: 30px;
}

.copy-secret-button {
  border-radius: 0 0 3px 3px;
  display: block;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: -2px;
  padding: 3px 8px;
  font-size: 0.8em;
}

.decrypted-message {
  min-height: 200px;
  margin-bottom: 0;
}

.header-logo {
  border-bottom: 1px solid $color-light-gray-2;
  padding: 2rem;

  img {
    width: 191px;
  }
}

.footer-container {
  border-top: 1px solid $color-light-gray-2;
  background: #FFFFFF;
}

.input-with-border {
  border: 1px solid $color-light-gray-2;
  border-radius: 3px;

  &:focus {
    box-shadow: none;
    border-color: $color-blue;
  }
}

.secret-submit-container {
  float: right;
}

.upload-file-container {
  line-height: 52px;
  float: left;
}

.secret-result {
  input.form-control {
    height: calc(2.55rem + 2px);
  }
}

.feature-inner {
  min-height: 116px;
}

.shield {
  margin-top: 2px;
}

.folder {
  margin-top: 6px;
}

.letter {
  margin-top: 6px;
}

@media (max-width: 1199px) {
  .feature-inner {
    min-height: 182px;
  }

  .feature-box {
    margin-bottom: 30px;
  }

  .title-icon {
    display: none;
  }
}

@media (max-width: 767px) {
  .feature-inner {
    min-height: 224px;
  }

  .upload-file-container {
    float: initial;
  }
}

@media (max-width: 575px) {
  .feature-inner {
    min-height: 75px;
  }
}

@media (max-width: 499px) {
  .exclusive-share,
  .encrypt-message {
    display: block;
    width: 100%;
  }

  .exclusive-share {
    margin-left: 20px;
  }

  .secret-submit-container {
    float: initial;
  }
}

@media (min-width: 1300px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1200px;
  }

  .footer-container {
    position: fixed;
    width: 100%;
    bottom: 0;
  }
}

.loader-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader,
.loader:before,
.loader:after {
  background: $color-blue;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: $color-blue;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
